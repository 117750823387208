/* eslint-disable import/extensions */
import { LitElement, html, css } from "lit";
import { normalize, openprops } from "./shared/openprops.css.js";

import "./my-rating.js";
// import { store } from '../store';

class MyHouseCard extends LitElement {
  static get styles() {
    // language=CSS
    return css`
      ${normalize}
      ${openprops}
       :host {
        width: 660px;
        height: 220px;
        display: grid;
        grid-template-columns: 330px 1fr;
        background-color: #fff;
        border-radius: var(--radius-2);
      }
      a {
        margin-inline: 0 !important;
        padding-inline: 0 !important;
      }

      .image,
      .content {
        height: 220px;
      }
      .price {
        font-size: 30px;
      }
      .questionary {
        color: var(--green-8);
        font-size: 1.5em;
      }
      my-rating {
        position: relative;
        left: 200px;
        top: -30px;
        --my-rating-active-color: var(--red-3);
        --my-rating-inactive-color: var(--red-0);
      }

      img {
        border-radius: var(--radius-2) 0 0 var(--radius-2);
      }

      .content {
        padding: var(--size-3);
      }

      .align-right {
        float: right;
        text-align: right;
      }

      .region-country {
        font-size: 0.8em;
      }

      .price {
        font-size: 2em;
      }

      .fullwidth {
        width: 100%;
      }

      svg {
        display: inline-block;
        vertical-align: middle;
      }

      .discountprice {
        text-decoration: line-through;
        font-size: 1.2em;
        color: var(--disabled-text-color);
        margin-right: 0.5em;
      }

      .fromtext {
        width: 100%;
        color: var(--gray-6);
        margin-top: 0.5em;
      }
      .rating {
        vertical-align:middle;
        float: right;
      }

      @media screen and (max-width: 414px) {
        :host {
          width: 330px;
          height: 440px;
          margin: 0 0 1em 0;
          grid-template-columns: 330px;
        }
        img {
          border-radius: var(--radius-2)  var(--radius-2) 0 0;
        }
      }
    `;
  }

  firstUpdated() {
    // set search variables from location
    this.locationSearch = window.location.search;
  }

  render() {
    const { housedata, _createDateString } = this;
    let imageUrl = this.smallWidth
      ? this.housedata.width293.URL
      : this.housedata.width330.URL;
    imageUrl = `/api/imageproxy/${imageUrl}`;
    return html`
      <div class="image">
        <a
          href="/vakantiehuis/${housedata.country}/${housedata.region.replace(
            /\//g,
            "|"
          )}/${housedata.code}/${this.locationSearch}"
          class="imagehref"
          target="_blank"
        >
          <img
            alt=""
            loading="lazy"
            width="${this.smallWidth ? 293 : 330}"
            height="220"
            src="${imageUrl}"
        /></a>
        <my-rating .amount=${housedata.stars} icon="favorite"></my-rating>
      </div>
      <div class="content">
        ${housedata.city}
        <div class="align-right questionary">
          ${parseInt(housedata.questionary_points, 10) !== 0
            ? html`
                <div class="rating"><svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
                  <path
                    fill="currentColor"
                    d="M5 21q-.825 0-1.413-.587Q3 19.825 3 19V5q0-.825.587-1.413Q4.175 3 5 3h4.2q.325-.9 1.088-1.45Q11.05 1 12 1t1.713.55Q14.475 2.1 14.8 3H19q.825 0 1.413.587Q21 4.175 21 5v14q0 .825-.587 1.413Q19.825 21 19 21Zm0-2h14V5H5v14Zm2-2h7v-2H7Zm0-4h10v-2H7Zm0-4h10V7H7Zm5-4.75q.325 0 .538-.213.212-.212.212-.537 0-.325-.212-.538-.213-.212-.538-.212-.325 0-.537.212-.213.213-.213.538 0 .325.213.537.212.213.537.213ZM5 19V5v14Z"
                  />
                </svg>${housedata.questionary_points / 10}</div>
              `
            : html``}
        </div>
        <div class="region-country">
          ${housedata.region} - ${housedata.country}
        </div>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          width="24"
          fill="currentColor"
        >
          <path
            d="M1 19V4h2v10h8V6h8q1.65 0 2.825 1.175Q23 8.35 23 10v9h-2v-3H3v3Zm6-6q-1.25 0-2.125-.875T4 10q0-1.25.875-2.125T7 7q1.25 0 2.125.875T10 10q0 1.25-.875 2.125T7 13Zm6 1h8v-4q0-.825-.587-1.413Q19.825 8 19 8h-6Zm-6-3q.425 0 .713-.288Q8 10.425 8 10t-.287-.713Q7.425 9 7 9t-.713.287Q6 9.575 6 10t.287.712Q6.575 11 7 11Zm0 0q-.425 0-.713-.288Q6 10.425 6 10t.287-.713Q6.575 9 7 9t.713.287Q8 9.575 8 10t-.287.712Q7.425 11 7 11Zm6-3h6q.825 0 1.413.587Q21 9.175 21 10v4h-8Z"
          />
        </svg>
        ${housedata.bedrooms} slaapkamers
        <br>
        <span
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            width="24"
            fill="currentColor"
          >
            <path
              d="M1 20v-2.8q0-.85.438-1.563.437-.712 1.162-1.087 1.55-.775 3.15-1.163Q7.35 13 9 13t3.25.387q1.6.388 3.15 1.163.725.375 1.162 1.087Q17 16.35 17 17.2V20Zm18 0v-3q0-1.1-.612-2.113-.613-1.012-1.738-1.737 1.275.15 2.4.512 1.125.363 2.1.888.9.5 1.375 1.112Q23 16.275 23 17v3ZM9 12q-1.65 0-2.825-1.175Q5 9.65 5 8q0-1.65 1.175-2.825Q7.35 4 9 4q1.65 0 2.825 1.175Q13 6.35 13 8q0 1.65-1.175 2.825Q10.65 12 9 12Zm10-4q0 1.65-1.175 2.825Q16.65 12 15 12q-.275 0-.7-.062-.425-.063-.7-.138.675-.8 1.037-1.775Q15 9.05 15 8q0-1.05-.363-2.025Q14.275 5 13.6 4.2q.35-.125.7-.163Q14.65 4 15 4q1.65 0 2.825 1.175Q19 6.35 19 8Z"
            />
          </svg>
          ${housedata.maxpersons} personen</span
        >
        <br />
        <div class="align-right fromtext">
          ${_createDateString(housedata.arrival, housedata.departure)}
        </div>
        <div class="align-right fullwidth">
          ${housedata.prediscountprice > housedata.price
            ? html`
                <span class="discountprice"
                  >€ ${housedata.prediscountprice}</span
                >
              `
            : ""}
          <span class="price">€ ${housedata.price}</span>
        </div>
      </div>
    `;
  }

  static get properties() {
    return {
      housedata: {
        type: Object,
      },
      smallWidth: {
        type: Boolean,
      },
      uriParameters: {
        type: String,
        value: "",
      },
      price: {
        type: Number,
      },
      locationSearch: {
        type: String,
      },
    };
  }

  static get is() {
    return "my-house-card";
  }

  stateChanged(state) {
    this.smallWidth = state.app.width === "small";
  }

  // eslint-disable-next-line class-methods-use-this
  _createDateString(arrival, departure) {
    const monthNames = [
      "januari",
      "februari",
      "maart",
      "april",
      "mei",
      "juni",
      "juli",
      "augustus",
      "september",
      "oktober",
      "november",
      "december",
    ];
    const arrivalDateObj = new Date(arrival);
    const departureDateObj = new Date(departure);
    let arrivalDate = "";

    if (arrivalDateObj.getMonth() === departureDateObj.getMonth()) {
      arrivalDate = arrivalDateObj.getDate();
    } else if (
      arrivalDateObj.getFullYear() === departureDateObj.getFullYear()
    ) {
      arrivalDate = `${arrivalDateObj.getDate()} ${
        monthNames[arrivalDateObj.getMonth()]
      }`;
    } else {
      arrivalDate = `${arrivalDateObj.getDate()} ${
        monthNames[arrivalDateObj.getMonth()]
      } ${arrivalDateObj.getFullYear()}`;
    }
    return `${arrivalDate} - ${departureDateObj.getDate()} ${
      monthNames[departureDateObj.getMonth()]
    } ${departureDateObj.getFullYear()}`;
  }
}

customElements.define(MyHouseCard.is, MyHouseCard);
