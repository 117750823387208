/* eslint-disable import/extensions */
import { LitElement, html, css } from "lit";

class MyRating extends LitElement {
  static get styles() {
    return css`

      svg[active] {
        color: var(--my-rating-active-color, --accent-color);
      }

      svg:not([active]) {
        color: var(--my-rating-inactive-color, --disabled-text-color);
      }
    `;
  }

  render() {
    return html`
      ${this._computeStars(this.max, this.amount).map((active) => {
        return html`<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="currentColor" ?active="${active}"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"/></svg>`;
      })}
    `;
  }

  constructor() {
    super();
    this.max = 5;
  }

  // eslint-disable-next-line class-methods-use-this
  _computeStars(max, rating) {
    // eslint-disable-next-line prefer-spread
    const result = Array.apply(null, Array(max)).map(
      Boolean.prototype.valueOf,
      false
    );
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < Math.round(rating); i++) {
      result[i] = true;
    }
    return result;
  }

  static get properties() {
    return {
      amount: {
        type: Number,
      },
      max: {
        type: Number,
      },
    };
  }

  _amountChanged(newValue) {
    this._stars = this._computeStars(this.max, newValue);
  }

  static get is() {
    return "my-rating";
  }
}

customElements.define(MyRating.is, MyRating);
