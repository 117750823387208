/* eslint-disable import/extensions */
import { LitElement, html, css } from "lit";

import { regions } from "./shared/regions.js";
import { normalize, openprops } from "./shared/openprops.css.js";

class ListRegions extends LitElement {
  static get styles() {
    return css`
      ${openprops}
      ${normalize}
      :host {
        display: flex;
        flex-wrap: wrap;
        font-family: Arial, Helvetica, sans-serif;
        background-color: var(--gray-8);
        color: var(--gray-1);
        padding: var(--size-4);
        font-size: var(--font-size-3);
        box-shadow: var(--shadow-5);
        border-radius: var(--radius-2);
      }

      div {
        width: var(--size-14);
      }

      a,
      a:link,
      a:visited,
      a:hover,
      a:focus,
      a:active {
        color: inherit;
        text-decoration: none;
      }
    `;
  }

  render() {
    return html`
        ${regions.map((region) => {
          return html`<div>
            <a
              href="/vakantiehuis/Nederland/${region[0]}/pagina/1?personen=1&amp;sortvolgorde=desc&amp;sorteertype=hartjes"
            >
              ${region[1]}</a
            >
          </div>`;
        })}
    `;
  }

  constructor() {
    super();
  }

  static get properties() {
    return {
      country: {
        type: String,
      },
    };
  }

  static get is() {
    return "list-regions";
  }
}

customElements.define(ListRegions.is, ListRegions);
