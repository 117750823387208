import { LitElement, html, css } from "lit";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import { ifDefined } from "lit-html/directives/if-defined.js";

import "./my-house-card.js";
import "./list-regions.js";
import { normalize, openprops } from "./shared/openprops.css.js";

class MyHome extends LitElement {
  constructor() {
    super();
    this.houses = [];
    this.site = "";
  }

  //@todo how to know we navigated back

  static get properties() {
    return {
      houses: { type: Array },
      body: { type: String },
      title: { type: String },
      image: { type: Object },
      site: { type: String },
      texts: { type: Object },
      persons: { type: String },
    };
  }

  static get styles() {
    return css`
      ${openprops}
      ${normalize}
      .container {
        display: flex;
        flex-flow: column wrap;
        align-items: flex-start;
        justify-content: center;
        background-size: 100%;
        background-position: center top;
        background-repeat: no-repeat;
        width: 100%;
        color: var(--primary-text-color);
      }

      h1 {
        text-align: center;
        margin-bottom: 0;
      }

      my-house-card {
          margin-bottom: var(--size-3);
        }

      list-regions {
        width: calc(var(--size-14) * 2.5);
      }

      .thuiswinkel {
        background: url("./images/thuiswinkel.svg");
      }

      .anvr {
        background: url("./images/anvr.svg");
      }

      .sgr {
        background: url("./images/sgr.svg");
      }

      .thuiswinkel,
      .sgr,
      .anvr {
        background-repeat: no-repeat;
        height: 50px;
        width: 50px;
        float: right;
        margin-top: 10px;
        margin-right: 10px;
      }
      .countrydescription {
        padding: var(--size-8) 0;
        width: 100%;
      }

      .countrydescription {
        max-width: 630px;
        margin: 1em auto;
      }

      .countrydescription ul {
        padding-inline-start: var(--size-4);
      }

      .countrydescription p {
        padding-top: var(--size-3);
      }

      .houses, .regions {
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
      }

      h2 {
        text-align: center;
        width: 100%;
        padding-bottom: var(--size-3);
      }

      .waarborgen {
        padding: var(--size-7) 0 var(--size-10) 0;
        width: 100%;
        display: flex;
        justify-content: center;
      }

      .waarborgen span {
        padding-top: 1.5em;
        padding-right: 1.5em;
        color: var(--disabled-text-color);
      }


      search-form {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 300px;
        min-height: 80px;
      }

      @media screen and (max-width: 414px) {
        .container {
          background-size: 200%;
        }
        .countrydescription {
          max-width: 330px;
        }
        search-form {
          margin-top: 150px;
          min-height: 403px;
        }
        h1, h2 {
          font-weight: var(--font-weight-7);
        }
        h2 {
          font-size: var(--font-size-4);
        }
        h1 {
          font-size: var(--font-size-5);
        }
        list-regions {
        width: calc(var(--size-14) * 1);
      }
        
      }
    `;
  }

  render() {
    // language=HTML
    return html`
      <div
        class="container"
        id="container"
        style="background-image: url(${ifDefined(this.image)});"
      >
        <search-form persons=${this.persons}></search-form>
        <div class="waarborgen">
          <span>Aangesloten bij: </span>
          <div class="thuiswinkel"></div>
          <div class="anvr"></div>
          <div class="sgr"></div>
        </div>
        <div class="houses">
          <h2>Populaire vakantiehuizen</h2>
          <div class="houseslist">
            ${this.houses.map(
              (item) =>
                html`<my-house-card .housedata=${item}></my-house-card> `
            )}
          </div>
        </div>
        <div class="countrydescription">
          <h1>${this.title ? html` ${this.title} ` : ""}</h1>
          ${unsafeHTML(this.body)}
        </div>
        <div class="regions">
          <h2>Regios</h2>
          <list-regions></list-regions>
        </div>
      </div>
    `;
  }

  willUpdate() {
    this.body = this.texts.introtext;
    this.title = this.texts.title;
    this.image = this.texts.headerimage;
  }

  async firstUpdated() {
    await this._setMatomo();
  }

  async _setMatomo() {
    window._paq.push(["setCustomUrl", window.location.pathname]);
    window._paq.push(["setDocumentTitle", "Homepage"]);
    window._paq.push(["trackPageView"]);
  }
}

globalThis.customElements.define("my-home", MyHome);
